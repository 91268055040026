import React from "react";
import NavigationBar from "../components/navigationBar";
import { Link } from "react-router-dom";
import battleForGloryImage from "../images/battleForGlory.png";
import witchLegacyImage from "../images/witchPlatformer.png";

class Games extends React.Component {
  render() {
    return (
      <div>
        <NavigationBar />
        <div className="body-container container-fluid d-flex flex-column align-items-center justify-content-center">
          <h1 className="p-5">Games I'm developing</h1>
          <div className="d-flex flex-row mb-2 flex-wrap justify-content-center">
            <div className="card m-3" style={{ maxWidth: 400 }}>
              <img
                src={battleForGloryImage}
                className="card-img-top"
                alt="..."
              />
              <div className="card-body d-flex flex-column">
                <h5 className="card-title fs-1">Battle for Glory</h5>
                <p className="card-text fs-5">
                  Battle online with other players.
                </p>
                <Link
                  to="/battle-for-glory"
                  className="btn btn-primary fs-3 align-self-center"
                >
                  Play Game
                </Link>
              </div>
            </div>
            <div className="card m-3" style={{ maxWidth: 400 }}>
              <img src={witchLegacyImage} className="card-img-top" alt="..." />
              <div className="card-body d-flex flex-column">
                <h5 className="card-title fs-1">Witch Legacy</h5>
                <p className="card-text fs-5">Platformer roguelike game.</p>
                <Link
                  to="/witch-legacy"
                  className="btn btn-primary fs-3 align-self-center"
                >
                  Play Game
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Games;
