import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";

import Home from "./pages/home";
import Games from "./pages/games";
import About from "./pages/about";
import Contact from "./pages/contact";
import BattleForGlory from "./pages/battleForGlory";
import WitchLegacy from "./pages/witchLegacy";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route index element={<Home />} />
        <Route path="games" element={<Games />} />
        <Route path="about" element={<About />} />
        <Route path="contact" element={<Contact />} />
        <Route path="battle-for-glory" element={<BattleForGlory />} />
        <Route path="witch-legacy" element={<WitchLegacy />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
