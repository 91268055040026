import React from "react";

class WitchLegacy extends React.Component {

  enterFullscreen = () => {
    let elem = document.querySelector("iframe");
    elem?.requestFullscreen();
    
  };
  
  render() {
    return (
      <div style={{ height: "100%", width: "100%", position:"relative" }}>
        <iframe
          allow="fullscreen"
          className="border-0 d-block"
          id=""
          src="/games/WitchLegacy/index.html"
          style={{ height: "100vh", width: "100vw", overflow: "hidden", top: "0", position:"absolute", zIndex: 1}}
        ></iframe>
        <button onClick={this.enterFullscreen} style={{ marginBottom: "10px", zIndex: 2, position:"relative"}}>
          Go Fullscreen
        </button>
      </div>
    );
  }
}

export default WitchLegacy;
