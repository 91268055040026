import React from "react";
import NavigationBar from "../components/navigationBar";

class Home extends React.Component {
  render() {
    return (
      <div>
        <NavigationBar />
        <div className="body-container container-fluid d-flex flex-column align-items-center justify-content-center">
          <h1 className="p-5">Welcome!</h1>
        </div>
      </div>
    );
  }
}

export default Home;
