import React from "react";
import NavigationBar from "../components/navigationBar";

const currentYear = new Date().getFullYear();
class About extends React.Component {
  render() {
    return (
      <div>
        <NavigationBar />
        <div className="body-container container-fluid d-flex flex-column align-items-center justify-content-center">
          <h1 className="p-5">About Me</h1>
          <h3>I'm Mihai. I lift weights and I make videogames.</h3>
        </div>
      </div>
    );
  }
}

export default About;
